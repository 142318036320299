import React from 'react';
import { X } from 'react-feather';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';

interface ConversationItemProps {
  item: ItemType;
  onDelete: (id: string) => void;
}

export const ConversationItem: React.FC<ConversationItemProps> = ({ item, onDelete }) => {
  return (
    <div className="flex gap-4 mb-4 relative group">
      <div className={`w-20 flex-shrink-0 ${
        item.role === 'user' ? 'text-blue-500' : 
        item.role === 'assistant' ? 'text-green-600' : ''
      }`}>
        <div>
          {(item.role || item.type).replaceAll('_', ' ')}
        </div>
        <button
          onClick={() => onDelete(item.id)}
          className="absolute right-0 hidden group-hover:flex bg-gray-400 hover:bg-gray-600 text-white rounded-full p-0.5 cursor-pointer"
        >
          <X className="w-3 h-3 stroke-[3]" />
        </button>
      </div>
      <div className="flex-grow text-gray-900">
        {item.type === 'function_call_output' && (
          <div>{item.formatted.output}</div>
        )}
        {!!item.formatted.tool && (
          <div>
            {item.formatted.tool.name}(
            {item.formatted.tool.arguments})
          </div>
        )}
        {!item.formatted.tool && item.role === 'user' && (
          <div>
            {item.formatted.transcript ||
              (item.formatted.audio?.length
                ? '(awaiting transcript)'
                : item.formatted.text ||
                  '(item sent)')}
          </div>
        )}
        {!item.formatted.tool && item.role === 'assistant' && (
          <div>
            {item.formatted.transcript ||
              item.formatted.text ||
              '(truncated)'}
          </div>
        )}
      </div>
    </div>
  );
};