import React from 'react';
import { MainNavbar } from '../components/navbar/MainNavbar';

export const AboutPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
        <MainNavbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">
            About AI Debate Coach
          </h1>
          <div className="prose prose-blue">
            <p className="text-lg text-gray-500">
              AI Debate Coach is an innovative platform that uses advanced artificial intelligence to help users improve their debating skills. Our system engages users in real-time debates, providing challenging counterarguments and helping them develop stronger rhetorical abilities.
            </p>
            <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">
              Key Features
            </h2>
            <ul className="list-disc list-inside text-gray-500 space-y-2">
              <li>Real-time AI-powered debates</li>
              <li>Voice interaction capabilities</li>
              <li>Instant feedback and evaluation</li>
              <li>Various debate topics to choose from</li>
              <li>Practice at your own pace</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};