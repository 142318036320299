import React from 'react';
import { Dropdown } from '../dropdown/Dropdown';
import { Input } from '../input/Input';

interface ThemeSelectorProps {
  debateTheme: string;
  customTheme: string;
  setDebateTheme: (theme: string) => void;
  setCustomTheme: (theme: string) => void;
}

export const ThemeSelector: React.FC<ThemeSelectorProps> = ({
  debateTheme,
  customTheme,
  setDebateTheme,
  setCustomTheme,
}) => {
  return (
    <div className="space-y-4 p-6 bg-white rounded-2xl shadow-sm mb-6">
      <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
        <label className="flex-shrink-0 text-gray-700 font-medium">
          Select Debate Theme:
        </label>
        <select
          value={debateTheme}
          onChange={(e) => setDebateTheme(e.target.value)}
          className="flex-grow px-4 py-2 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-gray-50 hover:bg-gray-100"
        >
          <option value="Is social media bad?">Is social media bad?</option>
          <option value="Should homework be banned?">Should homework be banned?</option>
          <option value="Custom theme">Custom theme</option>
        </select>
      </div>

      {debateTheme === 'Custom theme' && (
        <div className="animate-fadeIn">
          <input
            type="text"
            value={customTheme}
            onChange={(e) => setCustomTheme(e.target.value)}
            placeholder="Enter your debate topic..."
            className="w-full px-4 py-2 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-gray-50 hover:bg-gray-100"
          />
        </div>
      )}
    </div>
  );
};