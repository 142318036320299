import React, { useState } from 'react';
import { Edit, Menu, X } from 'react-feather';
import { Button } from '../button/Button';

interface NavbarProps {
  apiKey: string;
  resetAPIKey: () => void;
  showApiKey?: boolean;
}

export function Navbar({ apiKey, resetAPIKey, showApiKey = true }: NavbarProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { label: 'Home', href: '/' },
    { label: 'About', href: '/about' },
    { label: 'Settings', href: '/settings' },
    // Add more menu items as needed
  ];

  return (
    <nav className="bg-gradient-to-r from-blue-500 to-indigo-600 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo/Brand */}
          <div className="flex-shrink-0">
            <div className="text-white text-xl font-bold tracking-wider hover:text-blue-200 transition-colors duration-200">
              AI Debate Coach
            </div>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-4">
            {menuItems.map((item) => (
              <a
                key={item.label}
                href={item.href}
                className="text-blue-100 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200"
              >
                {item.label}
              </a>
            ))}
            {showApiKey && (
              <Button
                icon={Edit}
                iconPosition="end"
                buttonStyle="flush"
                label={`API: ${apiKey.slice(0, 3)}...`}
                onClick={resetAPIKey}
                className="text-blue-100 hover:text-white"
              />
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-blue-600">
          {menuItems.map((item) => (
            <a
              key={item.label}
              href={item.href}
              className="text-blue-100 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition-colors duration-200"
            >
              {item.label}
            </a>
          ))}
          {showApiKey && (
            <Button
              icon={Edit}
              iconPosition="end"
              buttonStyle="flush"
              label={`API: ${apiKey.slice(0, 3)}...`}
              onClick={resetAPIKey}
              className="text-blue-100 hover:text-white w-full text-left px-3 py-2"
            />
          )}
        </div>
      </div>
    </nav>
  );
}