import React, { useState } from 'react';
import { Edit } from 'react-feather';
import { Button } from '../components/button/Button';
import { ThemeSelector } from '../components/theme/ThemeSelector';
import { ConversationItem } from '../components/conversation/ConversationItem';
import { ControlButtons } from '../components/controls/ControlButtons';
import { useDebateSession } from '../hooks/useDebateSession';
import { Navbar } from '../components/navbar/Navbar';

export function ConsolePage() {
  const [debateTheme, setDebateTheme] = useState<string>('Is social media bad?');
  const [customTheme, setCustomTheme] = useState<string>('');

  const {
    isConnected,
    isRecording,
    items,
    apiKey,
    LOCAL_RELAY_SERVER_URL,
    connectConversation,
    disconnectConversation,
    startRecording,
    stopRecording,
    deleteConversationItem,
    resetAPIKey,
  } = useDebateSession();

  const handleConnect = async () => {
    const message = customTheme.trim() !== '' ? customTheme : debateTheme;
    await connectConversation(message);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-gray-50 to-blue-50">
      <Navbar 
        apiKey={apiKey} 
        resetAPIKey={resetAPIKey} 
        showApiKey={!LOCAL_RELAY_SERVER_URL}
      />

      <div className="flex-grow flex-shrink overflow-hidden px-4 sm:px-6 lg:px-8 mb-24">
        <div className="max-w-5xl mx-auto w-full flex flex-col flex-grow overflow-hidden pt-6">
          <ThemeSelector
            debateTheme={debateTheme}
            customTheme={customTheme}
            setDebateTheme={setDebateTheme}
            setCustomTheme={setCustomTheme}
          />

          <div className="flex flex-col flex-grow min-h-0 bg-white rounded-2xl shadow-sm p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-gray-900">Conversation</h2>
              <div className="text-sm text-gray-500">
                {items.length} messages
              </div>
            </div>
            <div className="flex-grow overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
              {!items.length && (
                <div className="flex items-center justify-center h-full text-gray-500 italic">
                  Start a conversation by connecting...
                </div>
              )}
              {items.map((item) => (
                <ConversationItem
                  key={item.id}
                  item={item}
                  onDelete={deleteConversationItem}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0">
        <ControlButtons
          isConnected={isConnected}
          isRecording={isRecording}
          itemsLength={items.length}
          onStartRecording={startRecording}
          onStopRecording={stopRecording}
          onConnect={handleConnect}
          onDisconnect={disconnectConversation}
        />
      </div>
    </div>
  );
}