import React from 'react';
import { X, Zap } from 'react-feather';
import { Button } from '../button/Button';

interface ControlButtonsProps {
  isConnected: boolean;
  isRecording: boolean;
  itemsLength: number;
  onStartRecording: () => Promise<void>;
  onStopRecording: () => Promise<void>;
  onConnect: () => Promise<void>;
  onDisconnect: () => Promise<void>;
  isEvaluating?: boolean;
  onEvaluate?: () => void;
}

export const ControlButtons: React.FC<ControlButtonsProps> = ({
  isConnected,
  isRecording,
  itemsLength,
  onStartRecording,
  onStopRecording,
  onConnect,
  onDisconnect,
  isEvaluating,
  onEvaluate,
}) => {
  return (
    <div className="flex items-center justify-center gap-6 p-6 bg-gradient-to-r from-gray-50 to-gray-100 backdrop-blur-sm border-t border-gray-200">
      <div className="flex gap-4 max-w-3xl w-full">
        {isConnected && (
          <Button
            label={isRecording ? 'Release to Send' : 'Push to Talk'}
            buttonStyle={isRecording ? 'alert' : 'regular'}
            disabled={!isConnected}
            onMouseDown={onStartRecording}
            onMouseUp={onStopRecording}
            className={`flex-1 py-4 text-lg font-medium rounded-xl shadow-lg transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98] ${
              isRecording 
                ? 'bg-red-500 hover:bg-red-600 animate-pulse' 
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          />
        )}
        {isConnected && (
          <Button
            label={isEvaluating ? 'Evaluating...' : 'Evaluate debate'}
            buttonStyle="action"
            disabled={isEvaluating || itemsLength === 0}
            onClick={onEvaluate}
            className="flex-1 py-4 text-lg font-medium rounded-xl shadow-lg bg-emerald-500 hover:bg-emerald-600 transition-all duration-200 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed"
          />
        )}
        <Button
          label={isConnected ? 'Disconnect' : 'Connect'}
          iconPosition={isConnected ? 'end' : 'start'}
          icon={isConnected ? X : Zap}
          buttonStyle={isConnected ? 'regular' : 'action'}
          onClick={isConnected ? onDisconnect : onConnect}
          className={`px-8 py-4 text-lg font-medium rounded-xl shadow-lg transition-all duration-200 transform hover:scale-[1.02] ${
            isConnected 
              ? 'bg-gray-200 hover:bg-gray-300 text-gray-700' 
              : 'bg-indigo-500 hover:bg-indigo-600 text-white'
          }`}
        />
      </div>
    </div>
  );
};